<template>
  <div class="team-statistic">
    <ContractSideMenu :active-index="6">
    </ContractSideMenu>
    <div class="right-panel">

    <MetaRightSideHeader>
      <template #title>统计</template>

    </MetaRightSideHeader>
    <div class="panel" >
      <div class="action">
        <div class="pagination-area">
          <MetaListPagination
              :current-count="currentCount"
              :size="size"
              :current-index="currentIndex"
              :total-count="totalCount"
              @size-change="sizeChange"
              @current-change="currentChange"
          ></MetaListPagination>
        </div>
      </div>
      <div class="card-list custom-scroller" ref="cardList">
        <div class="holder"></div>
        <div class="card"
             :style="{
                flexBasis:cardWidth + 'px',
                height:cardHeight+ 'px'
             }"
             v-for="(visualization,index) in visualizations" :key="index">
          <div class="chart-card">
            <component
                :is="getComponent(visualization.param.chartType)"
                v-if="visualization.param.chartData"
                :chart-data="visualization.param.chartData"
                :x_Axis="visualization.param.xAxis"
                :_legend="{
                  show:false
                }"
                :min-width="'400px'"
            ></component>
          </div>
          <div class="card-info-bar">
            <span class="cib-title">{{ visualization.param.configuration.data.title}}</span>

            <div class="cib-tools">
              <i class="material-icons cib-tool" @click="zoomVisualization(visualization)">zoom_out_map</i>
            </div>
          </div>
        </div>
        <div class="holder"></div>
      </div>
    </div>

    </div>

    <!--放大图表的弹窗-->
    <div class="team-statistic-zoom"
         v-if="showZoomArea">
      <dialog-close-btn
          offset-y="2px"
          @click="showZoomArea = false"></dialog-close-btn>
      <div class="zoom-chart-wrap">
        <component
            :is="getComponent(currentZoomVisualization.param.chartType)"
            v-if="currentZoomVisualization.param.chartData"
            :chart-data="currentZoomVisualization.param.chartData"
            :x_Axis="currentZoomVisualization.param.xAxis"
            :_legend="currentZoomVisualization.param.legend"
            :min-width="'400px'"
        ></component>
      </div>
    </div>
  </div>

</template>

<script>
import {CHART_TYPES} from "../components/charts/js/statisticChartConfig";

import {getTeamVisualization, visualizationSearch} from "../api/api";
import {colorLog, debounce, deepClone, DomEventListenerManager} from "../util";
import {defineAsyncComponent} from "vue";
import ContractSideMenu from "../components/ContractSideMenu";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import MetaListPagination from "../components/list/MetaListPagination";
import DialogCloseBtn from "../components/universalUI/DialogCloseBtn";


export default {
  name: "TeamStatistics",
  computed:{

  },
  components:{
    DialogCloseBtn,
    MetaListPagination,
    MetaRightSideHeader,
    ContractSideMenu,
    StatisticRank: defineAsyncComponent(() => import('../components/charts/StatisticRank')),
    StatisticLine: defineAsyncComponent(() => import('../components/charts/StatisticLine')),
    StatisticBar: defineAsyncComponent(() => import('../components/charts/StatisticBar')),
    StatisticPie: defineAsyncComponent(() => import('../components/charts/StatisticPie'))
  },
  data(){
    return {
      domEventListenerManager: new DomEventListenerManager(),
      userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
      visualizations:[],
      cardHeight:0,
      cardWidth:0,
      currentZoomVisualization:null,
      showZoomArea:false,
      zoomWidth: 0,
      zoomHeight: 0,
    }
  },
  mounted() {
     getTeamVisualization(this.userInfo.mainSiteTeamId).then(res=>{
       if(res.data.code===0) {
         this.visualizations =res.data.data;
         this.visualizations.forEach((item)=>{
            this.loadChartData(item.param);
         })
       }
     });
    this.updateCardSize();
  },
  unmounted() {
  },
  methods:{
    zoomVisualization(visualization){
      this.currentZoomVisualization = visualization;
      this.$nextTick(()=>{
        this.showZoomArea = true;
      })
    },
    updateCardSize(){
      let cardList = this.$refs.cardList;
      cardList.clientHeight;

      let clientWidth = screen.width;
      colorLog.red('clientWidth',clientWidth);
      this.cardWidth = (cardList.clientWidth - clientWidth * .04) / 3;
      this.cardHeight = cardList.clientHeight / 2  - clientWidth * .015
    },
    getComponent(chartType){
      switch (chartType){
        case CHART_TYPES.LINE:
          return 'StatisticLine';
        case CHART_TYPES.TABLE:
          return 'StatisticRank';
        case CHART_TYPES.PIE:
          return 'StatisticPie';
        case CHART_TYPES.BAR:
          return 'StatisticBar';
      }
      return null
    },
    async loadChartData(param){
      param.currentFilterCount=0;
      let res = await visualizationSearch(param);
      const makeItem = (item)=>{
        let result = {};
        result.name=item.name;
        if(item.count!==null){
          result.value=item.count;
        }else if(item.value!==null){
          result.value=item.value;
        }
        if(item.name=='过滤器'){
          let filters= param.configuration.data.buckets.filter((item)=>item.aggregation=='过滤器')

          let filter = filters[param.currentFilterCount];

          if(item.aggs){
            let i = 0;
            item.aggs.forEach((subItem)=>{
              subItem.name=filter.filters[i++].value;

            })
          }
        }

        if(item.aggs!==null){
          result.children=[];
          item.aggs.forEach((subItem)=>{
            result.children.push(makeItem(subItem));
          })
        }
        if(result.children) {
          if (param.chartType === CHART_TYPES.PIE) {
            if (result.children.length === 2) {
              if (result.children[0].value == null) {
                result.value = result.children[1].value;
                result.children = result.children[0].children;

              } else if (result.children[1].value == null) {
                result.value = result.children[0].value;
                result.children = result.children[1].children;
              }
            } else if (result.children.length == 1) {
              if (result.children[0].value == null) {
                result.children = result.children[0].children;
              } else if (result.children[0].name.indexOf(param.configuration.data.metrics[0].aggregation) >= 0) {
                result.value = result.children[0].value;
                result.children = null;
              }
            }
            // if (result.children.length == 1 && result.children[0].value == null) {
            //   result.children = result.children[0].children;
            // }else if(result.children.length == 2 && result.children[0].value == null){
            //   result.value = result.children[1].value;
            //   result.children = result.children[0].children;
            // }


          }
        }

        return result;
      }


      if(res.data.code == 0){
        colorLog.red(res.data)
        if(res.data.code == 0) {
          let result = res.data.data;
          if (param.chartType === CHART_TYPES.PIE) {

            let chartData = []

            result.forEach((item) => {
              chartData.push(makeItem(item))

            })



            param.chartData = chartData;
          }else if(param.chartType===CHART_TYPES.TABLE){
            param.chartData={}
            param.chartData.chartProps=[];
            let table= {
              column:[],
              data:[]
            };
            let i = 0;
            param.configuration.data.buckets.forEach((bucket)=>{

              table.column.push({
                label:bucket.aggregation=='条款'?bucket.field+":"+bucket.orderSort.order:bucket.aggregation=='过滤器'?"过滤器":"日期",
                prop:`fields[${i++}]`
              })
            })
            param.configuration.data.metrics.forEach((metric)=>{
              table.column.push({
                label:metric.field+metric.aggregation,
                prop:`fields[${i++}]`
              })
            })
            result.forEach((item)=>{

              this.getTableData(table,item,1,[])
            })
            param.chartData.chartProps = table.column;
            param.chartData.data = table.data.map((column)=>{
              return {fields:column}
            });


          }else if(param.chartType===CHART_TYPES.LINE){
            param.chartData = [];
            param.currentFilterCount=0;
            param.xAxis={type: 'category',
                boundaryGap: false,
                data: []};
           param.legend={data:[]}
            let map = {};
            result.forEach((item)=>{
              let name = ""
              if(isNaN(Date.parse(item.name))){
                name= item.name;
              }else{
                let date = new Date(item.name)
                name= item.name= date.toLocaleString();
              }
              param.xAxis.data.push(name)
              this.getData(param,map,item,name);


            })
            let needAllCount = true;
            if(param.configuration.data.metrics.find((item)=>item.aggregation==='总数')==null ){
              needAllCount=false;
            }
            Object.entries(map).forEach(item=>{
              let matchMetric = false;
              param.configuration.data.metrics.forEach((metric)=>{
                if((item[0].endsWith(metric.aggregation)&& item[0]!=='总数') || (needAllCount && item[0].startsWith('总数')&&item[0]!=='总数')){
                  matchMetric=true;
                }
              })
              if(matchMetric ||(needAllCount && param.configuration.data.buckets.length===1)) {
                let line = {name: item[0], type: "line", data: []}
                param.legend.data.push(item[0]);
                param.xAxis.data.forEach((x) => {
                  let value = item[1][x]==null?0:item[1][x];
                  line.data.push(value)
                })
                param.chartData.push(line);
              }
            })



          }else if(param.chartType===CHART_TYPES.BAR){
            param.chartData = [];
            param.currentFilterCount=0;
            param.xAxis={type: 'category',
              boundaryGap: false,
              data: []};
            param.legend={data:[]}
            let map = {};
            result.forEach((item)=>{

              let name = ""
              if(isNaN(Date.parse(item.name))){
                name= item.name;
              }else{
                let date = new Date(item.name)
                name= item.name= date.toLocaleString();
              }

              param.xAxis.data.push(name)
              this.getData(param,map,item,name);

            })
            let needAllCount = true;
            if(param.configuration.data.metrics.find((item)=>item.aggregation==='总数')==null ){
              needAllCount=false;
            }
            Object.entries(map).forEach(item=>{
              let matchMetric = false;
              param.configuration.data.metrics.forEach(metric=>{
                if((item[0].endsWith(metric.aggregation )&& item[0]!=='总数') || (needAllCount && item[0].startsWith('总数')&&item[0]!=='总数')){
                  matchMetric=true;
                }
              })
              if(matchMetric ||(needAllCount && param.configuration.data.buckets.length===1)) {
                let bar = {name: item[0], type: "bar", data: []}
                param.legend.data.push(item[0]);
                if (item[0].indexOf("-") >= 0) {
                  bar.stack = item[0].substring(0, item[0].lastIndexOf("-"))
                }
                param.xAxis.data.forEach((x) => {
                  bar.data.push(item[1][x])
                })
                param.chartData.push(bar);
              }
            })
          }
        }
      }



    },
    getData(param,map,item,xAxis,parent){
      if(item.count!=null){
        item.value=item.count;
      }
      if(item.value=='-Infinity'){
        item.value=0;
      }
      if(parent==null)parent="";
      let name = item.name==xAxis?"总数":parent+"-"+item.name;
      if(item.value!=null){
        if(map[name]==null){
          map[name]={};
        }
        map[name][xAxis]=item.value;
      }else{
        if(item.name=='过滤器'){
          let filters= param.configuration.data.buckets.filter((item)=>item.aggregation=='过滤器')
          let filter = filters[param.currentFilterCount];
          if(item.aggs){
            let i = 0;
            item.aggs.forEach((subItem)=>{
              subItem.name=filter.filters[i++].value;

            })
          }
        }
      }

      if(item.aggs){
        item.aggs.forEach((subItem)=>{
          this.getData(param,map,subItem,xAxis,name);
        })
      }
      if(item.percentiles){
        item.percentiles.forEach((percent)=>{
          if(map['percent:'+percent.value]==null){
            map['percent:'+percent.value]={}
          }
          map['percent:'+percent.value][xAxis]=percent.percent;
        })
      }

    },
    getTableData(table,item,index,column){
      let newColumn = deepClone(column);
      if(item.count){
        newColumn.push(item.name);
        if(index===1&&item.aggs==null){
          newColumn.push(item.count)
        }
      }
      else if(item.value){
        newColumn.push(item.value);
      }
      if((index==table.column.length && newColumn.length==index)||newColumn.length==table.column.length){
        table.data.push(newColumn)
      }else{

        if(item.aggs){
          item.aggs.forEach((subItem)=>{
            if(!item.count&&!item.value){
              this.getTableData(table,subItem,index,newColumn)
            }else{
              this.getTableData(table,subItem,index+1,newColumn)
            }
          })
        }
      }
    },
  }
}
</script>

<style scoped>
.team-statistic{
  display:flex;
  justify-content: space-between;
  height: 100%;
  background: rgb(248, 249, 250);
  overflow-y:hidden
}
.right-panel{
  padding: 0;
  min-width: 570px;

  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  width: calc(100% - 283px);

}

.panel{
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  width: 100%;

  background: white;
  z-index: 1;
  flex: 1;
  overflow: hidden;

}

.card{
  border-radius: 4px;
  padding:5px 5px 0;
  box-sizing: border-box;
  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}
.card:hover{
}
.card-list{
  overflow-y: auto;
  display: flex;
  flex-flow: row wrap;
  height: calc(100% - 40px);
  justify-content: space-evenly;
  row-gap: 1vw;
}
.card-list .holder{
  width: 100%;
  height: 0;
}
.chart-card{
  height: calc(100% - 2rem);
}
.card-info-bar{
  height: 2rem;
  box-shadow: 0 -1px 0 0 var(--gray-1);
  position: relative;
}
.card-info-bar::before{
  content:'';
  display:inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
.cib-title{
    vertical-align: middle;
}
.cib-tools{
  position: absolute;
  right:0;
  top: 0;
  bottom: 0;
  height: fit-content;
  margin: auto;
}
.cib-tool{
  font-size: 1.25rem;
  vertical-align: bottom;
  cursor:pointer;
  transition:.2s color;
}
.cib-tool:hover{
  color:var(--lightBlue);
}
.team-statistic-zoom{
  position: fixed;
  width: 150vh;
  height: 80vh;
  top: 0;
  bottom: 0;
  left:0;
  right:0;
  margin: auto;
  border-radius: 4px;
  box-shadow: var(--gray-2) 0px 0px 5px 2px;
  z-index: 1;
  background: white;
}
.zoom-chart-wrap{
  width: calc(100% - 1.5rem);
  height: 100%;
}
.action{
  height: 40px;
  border-bottom: solid 1px #ddd;
  display: flex;
  align-items: center;
  justify-content: right;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.pagination-area{


}

.meta-list-pagination{
  display: flex;
  align-items: center
}
</style>
